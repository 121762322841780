<template>
  <div class="winners page-block position-relative" id="winners-block">
    <div class="container-xl">
      <div class="row">
        <h2 class="text-secondary mx-auto">{{tr('winners-title')}}</h2>
        <div class="col-12" v-if="this.$store.state.winnersAll.length > 0">
          <div class="winners-block__inner">
            
            <!-- TAB -->
            <div class="winners-button-select d-none d-md-flex justify-content-center" >
              <!--<button 
                :class="{'active':winnerState === 'daily'}" 
                v-on:click="setWinnerState('daily');setGTM('nyertesek_daily')"
              >
                {{tr('winners-tab-1')}}
              </button>--> 
              <!--<button class="mx-3"
                :class="{'active':winnerState === 'weekly'}" 
                v-on:click="setWinnerState('weekly');setGTM('nyertesek_weekly')"
              >
                {{tr('winners-tab-2')}}
              </button>              
              <button 
                :class="{'active':winnerState === 'main'}" 
                v-on:click="setWinnerState('main');setGTM('nyertesek_main')"
              >{{tr('winners-tab-3')}}
              </button>-->
            </div>
            <!-- TAB - MOBILE -->
            <div class="dropdown winners-button-select--mobile d-none text-center">
              <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <span v-if="winnerState == 'main'">{{tr('winners-tab-3')}}</span>
                  <span v-else-if="winnerState == 'weekly'">{{tr('winners-tab-2')}}</span>
                  <!--<span v-else>{{tr('winners-tab-1')}}</span>-->
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">                
                <!--<div class="dropdown-item" v-on:click="setWinnerState('daily');setGTM('nyertesek_napi')">{{tr('winners-tab-1')}}</div>-->
              <!--  <div class="dropdown-item" v-on:click="setWinnerState('weekly');setGTM('nyertesek_heti')">{{tr('winners-tab-2')}}</div>
                <div class="dropdown-item" v-on:click="setWinnerState('main');setGTM('nyertesek_heti')">{{tr('winners-tab-3')}}</div>
             --> </div>
            </div>                   
            <div class="separator"></div>

            <!-- TABLE -->
            <div class="winners-wrap">
              <div class="winners__item">
                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block "  >
                  <thead>
                    <tr class="winners-top">
                      <th>{{tr('winners-table-th-1')}}</th>
                      <th>{{tr('winners-table-th-2')}}</th>
                      <th>{{tr('winners-table-th-3')}}</th>
                    </tr>
                  </thead>
                  <tbody v-if="filteredWinners && filteredWinners.length">
                      <!--<tr class="gutter-td">
                        <td colspan="3"></td>
                      </tr>-->
                      <tr class="winners-list" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                        <td class="winners-id">
                          <div><strong>{{ winner.name }}</strong></div> 
                          <div class="d-md-none">{{winnings[winnerState]}}</div>
                        </td>
                        <td v-if="winner.type == 'main'" class="winner-type-td d-none d-md-table-cell ">                          
                          {{winnings.main}}
                        </td>
                        <td v-else-if="winner.type == 'weekly'" class="winner-type-td d-none d-md-table-cell ">                          
                          {{winnings.weekly}}
                        </td>
                        <td v-else class="winner-type-td d-none d-md-table-cell ">                          
                          {{winnings.daily}}
                        </td>
                        <td class="winners-product text-right text-md-left">
                         <span>{{winner.winning_time}}</span>
                        </td>

                      </tr>
                      <!--<tr class="gutter-td">
                        <td colspan="3"></td>
                      </tr>-->
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td v-if="winnerState != 'main'" class="no-winner" colspan="4">
                        {{tr("winners-empty-table-weekly")}}
                      </td>
                      <td v-if="winnerState == 'main'" class="no-winner" colspan="4">
                         {{tr("winners-empty-table-weekly")}}
                        <!--{{tr("winners-empty-table-main")}} --> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="d-flex justify-content-center pt-4" v-if="needMoreBtn">
              <button type="button" class="btn btn-secondary" v-on:click="moreWinners(true)"> {{tr("winners-more-btn")}}</button>
            </div>
          </div>
        </div>
        <div class="col-12 text-center" v-else>
          <p class="text-white fw-bold">{{tr("winners-empty-table-weekly")}}</p>
          <img src="@/assets/img/icons/no-winner.png" class="img-fluid" width="15%">
        </div>
        <div class="col-12 col-lg-8 offset-lg-2">
          <p class="winners-more-text">{{tr("winners-info-text")}}  </p>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import promoStatus from "@/config/promoStatus";

export default {
  data() {
    return {
      winnerState: "main",
      winnerTypes: {
        napi: "Napi nyertes",
        weekly: "weekly nyertes",
        main: 'Fődíj nyertes'
      },
      winnings:{
        daily: this.tr('winning-type-daily'),
        weekly: this.tr('winning-type-weekly'),
        main: this.tr('winning-type-main')
      },
      needMoreBtn: false,
      needMoreWinners: false
    }
  },
  computed: {

    promotionIsActive() {
      return this.$store.state.promoStatus === promoStatus.live;
    },
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    },
    promotionIsAfter() {
      return this.$store.state.promoStatus === promoStatus.after;
    },
    filteredWinners() {
        let result;
        
        if(this.$store.state.winnersAll ?? []){
            result = this.$store.state.winnersAll.filter(w => w.type === this.winnerState)

            if(result.length > 10 && !this.needMoreWinners){
                this.checkBtn(true);
                return result.slice(0,10);
            }else{
                this.checkBtn(false);
                return result;
            }
        }
        this.checkBtn(false);
        return result;
    }
  },
  created() {
    this.$store.dispatch("getWinners");    
  },
  components: {},
  methods: {
    setGTM(item){

      this.GTtrackGA4({
        'event' : 'event',
        'category': 'nyertesek',
        'action': 'kattintas',
        'label': item,
        'clicked_text':item,
        'action_type': 'nyertesek_kattintas'
      })
    },
    setWinnerState(newState) {
        this.winnerState = newState;
        this.moreWinners(false);      
    },
    checkBtn(newState) {
        this.needMoreBtn = newState;
    },
    moreWinners(newState){
        this.needMoreWinners = newState;
    }
  }
}
</script>